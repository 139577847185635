import { FunctionComponent, ReactNode } from "react";
import styles from "@/components/ai-chat-assistant/message/message.module.scss";
import { IconExternal, Skeleton } from "@webomat/webomat-ui-react/build/esm";
import { GQLMessageRole } from "@/utils/graphql.generated";
import ReactMarkdown from "react-markdown";
import { GQLPublicAssistantByIdAvatarFragment } from "@/queries/assistant.generated";

interface MessageProps {
    message?: string | ReactNode;
    isTyping?: boolean;
    avatar?: GQLPublicAssistantByIdAvatarFragment | null;
    showAvatar?: boolean;
    showFooter?: boolean;
    footer?: string;
    role: GQLMessageRole;
    onClick?: () => void;
    key?: React.Key;
}

export const Message: FunctionComponent<MessageProps> = ({
    role,
    message,
    avatar,
    showAvatar = true,
    showFooter = true,
    isTyping,
    footer,
    onClick,
}) => {
    const CustomTag = onClick ? "button" : "div";

    return (
        <div
            className={`${styles.Wrapper} ${styles[`Wrapper--${role}`]} ${isTyping ? styles["Wrapper--is-typing"] : ""} ${
                showAvatar && avatar?.sizes?.[0] ? styles["Wrapper--with-avatar"] : ""
            } ${onClick ? styles["Wrapper--button"] : ""}`.trim()}
        >
            <div className={styles.Body}>
                {showAvatar && avatar?.sizes?.[0] && (
                    <div className={styles.Author}>
                        <div className={styles.AuthorImage}>
                            <img src={avatar.sizes[0].src} alt="Avatar" />
                        </div>
                    </div>
                )}
                <CustomTag className={styles.Message} onClick={onClick}>
                    {isTyping ? (
                        <div className={styles.Typing}>
                            <Skeleton width="8px" height="8px" rounded="full" className={styles.TypingSkeleton} />
                            <Skeleton width="8px" height="8px" rounded="full" className={styles.TypingSkeleton} />
                            <Skeleton width="8px" height="8px" rounded="full" className={styles.TypingSkeleton} />
                        </div>
                    ) : typeof message === "string" ? (
                        <ReactMarkdown
                            components={{
                                code({ node, inline, className, children, ...props }) {
                                    return !inline ? (
                                        <div className={styles.CodeWrapper}>
                                            <div className={styles.CodeInner}>
                                                <code className={className} {...props}>
                                                    {children}
                                                </code>
                                            </div>
                                        </div>
                                    ) : (
                                        <code className={className} {...props}>
                                            {children}
                                        </code>
                                    );
                                },
                                a({ href, children }) {
                                    return (
                                        <a href={href || "#"} target="_blank">
                                            <IconExternal />
                                            {children}
                                        </a>
                                    );
                                },
                                li({ children, ...rest }: any) {
                                    const childTagName = rest.node.children[0].tagName;
                                    return <li style={childTagName === "img" ? { listStyleType: "none" } : {}}>{children}</li>;
                                },
                                img({ src, alt }) {
                                    return (
                                        <div className={styles.Image}>
                                            <div className={styles.ImageInner}>
                                                <img src={src} alt={alt} />
                                            </div>
                                            <div className={styles.ImageBody}>
                                                <div className={styles.ImageName}>{alt}</div>
                                                {/*<div className={styles.ImagePrice}>{alt}</div>*/}
                                            </div>
                                        </div>
                                    );
                                },
                            }}
                        >
                            {message}
                        </ReactMarkdown>
                    ) : (
                        message
                    )}
                </CustomTag>
            </div>
            {showFooter && footer && <div className={styles.Footer}>{footer}</div>}
        </div>
    );
};
